// @flow

import * as React from 'react'
import cx from 'classnames'
import {ApplicationBadge, ShopItem, SavingsBadge} from '../../ui-components'

import * as styles from './PCRWorkstation.module.css'
import {PRICES, SAVINGS} from '../../prices'

type HeroProps = {
  imgSrc: string,
  mobileImgSrc: string,
}

const SHOP_ITEMS = [
  {
    itemName: 'With Thermocycler',
    itemPrice: PRICES.PcrWorkstation[0],
    itemDiscountPrice: PRICES.PcrWorkstation[1],
    buttonProps: {
      children: 'Order Workstation',
      className: styles.button,
      linkOutProps: {
        url: 'https://shop.opentrons.com/ot-2-pcr-workstation/?showHidden=true',
        gtm: {
          action: 'click',
          category: 'b-workstation',
          label: 'ngs-workstation',
        },
      },
      Component: 'a',
    },
  },
]

const SHOP_ITEMS_SECONDARY = [
  {
    itemName: 'Without Thermocycler',
    itemPrice: PRICES.PcrWorkstationSecondary[0],
    itemDiscountPrice: PRICES.PcrWorkstationSecondary[1],
    buttonProps: {
      children: 'Order Workstation',
      className: styles.button,
      linkOutProps: {
        url: 'https://shop.opentrons.com/ot-2-pcr-workstation/?showHidden=true',
        gtm: {
          action: 'click',
          category: 'b-workstation',
          label: 'ngs-workstation',
        },
      },
      Component: 'a',
    },
  },
]

export default function Hero(props: HeroProps) {
  const {imgSrc, mobileImgSrc} = props
  const badgeText = 'Verified Workstation Application'
  const title = 'PCR Workstation'
  const savings = SAVINGS.PcrWorkstation

  return (
    <div className={styles.bg_color}>
      <section className={cx(styles.container, styles.hero_container)}>
        <div className={styles.mobile_view}>
          <ApplicationBadge className={styles.badge} textSrc={badgeText} />
          <h2 className={styles.h2}>{title}</h2>
        </div>
        <div className={styles.image}>
          <SavingsBadge savings={savings} />
          <img src={imgSrc} alt="" />
        </div>
        <div className={styles.mobile_image}>
          <SavingsBadge savings={savings} />
          <img src={mobileImgSrc} alt="" />
        </div>
        <div className={styles.desktop_container}>
          <div className={styles.desktop_view}>
            <ApplicationBadge textSrc={badgeText} />
            <h2 className={styles.h2}>{title}</h2>
          </div>
          <p className={styles.p}>
            For PCR setup and thermocycling for up to 96 samples
          </p>
          <br />
          <p className={cx(styles.p, styles.italic_style)}>
            The PCR Workstation includes OT-2 Robot and Pipettes, Thermocycler
            (optional), Temperature Module, Tips and Labware, and Custom
            Protocol Development
          </p>
          <span className={styles.price_container}>
            <div className={styles.shop_item_container}>
              {SHOP_ITEMS.map((item, i) => {
                return <ShopItem key={i} {...item} />
              })}
            </div>
            <div className={styles.shop_item_container}>
              {SHOP_ITEMS_SECONDARY.map((item, i) => {
                return <ShopItem key={i} {...item} />
              })}
            </div>
          </span>
        </div>
      </section>
    </div>
  )
}
