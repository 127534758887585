import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import PCRWorkstation from '../../components/PCRWorkstation'

const PCRWorkstationPage = () => (
  <Page>
    <SEO title="PCR Workstation" description="PCR Workstation" />
    <PCRWorkstation />
  </Page>
)

export default PCRWorkstationPage
