// @flow

import * as React from 'react'
import {AppLink, BulletPoint} from '../../ui-components'

import * as styles from './PCRWorkstation.module.css'
import IconDataAndResults from './assets/icon_data_and_results.svg'
import IconDNA from './assets/icon_dna.svg'
import IconLibraryPrep from './assets/icon_library_prep.svg'
import IconOT2 from './assets/icon_ot2.svg'

export const CONTENT_INFO = (
  <div>
    <p className={styles.p}>
      With the OT-2 PCR Workstation, you can automate your end-point PCR setup
      and thermocycling workflows for up to 96 samples. Configure your
      workstation with an on-deck Thermocycler for end-to-end automation, or
      without a Thermocycler for automated PCR setup with off-deck
      thermocycling.
    </p>
  </div>
)

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'The OT-2 PCR Workstation can support the following workflow steps:',
  listBulletpoint: ['PCR setup', 'Thermocycling'],
}

export const LINKS = {
  Overview: {
    title: 'Overview',
    scrollId: 'overview',
  },
  WorkstationComponents: {
    title: 'Workstation Components',
    scrollId: 'materials',
  },
  ApplicationNotes: {
    title: 'Application Notes',
    scrollId: 'applicationnotes',
  },
  Protocols: {
    title: 'Protocols',
    scrollId: 'protocols',
  },
}

export const MENU_DATA = {
  menuData: {
    links: [
      LINKS.Overview.title,
      LINKS.WorkstationComponents.title,
      LINKS.ApplicationNotes.title,
      LINKS.Protocols.title,
    ],
    linkScrollIds: {
      [LINKS.Overview.title]: LINKS.Overview.scrollId,
      [LINKS.WorkstationComponents.title]: LINKS.WorkstationComponents.scrollId,
      [LINKS.ApplicationNotes.title]: LINKS.ApplicationNotes.scrollId,
      [LINKS.Protocols.title]: LINKS.Protocols.scrollId,
    },
    url: '/cleanupkit',
    workflow: 'Interested in automating your PCR workflow?',
  },
  gtmCategory: 'b-workstation',
  protocolGtmLabel: 'schedule-consultation',
  demoGtmLabel: 'schedule-demo',
}

export const SCROLL_MENU_DATA = [
  LINKS.Overview.scrollId,
  LINKS.WorkstationComponents.scrollId,
  LINKS.ApplicationNotes.scrollId,
  LINKS.Protocols.scrollId,
]

export const NOTE_DATA = [
  {
    note: (
      <AppLink
        url={
          'https://insights.opentrons.com/hubfs/Applications/PCR/Automating%20PCR%20Thermocycler%20App%20Note.pdf'
        }
      >
        Automating Sensitive Polymerase Chain Reaction Protocols Using the
        Opentrons Thermocycler on the Opentrons OT-2 Platform
      </AppLink>
    ),
  },
]

export const VALIDATION_DATA = (
  <p className={styles.p}>
    This application has been fully tested and scientifically verified by
    Opentrons.
  </p>
)

export const WARRANTY_DESCRIPTION = (
  <>
    <p>
      Opentrons aims to provide the best customer experience with our system. We
      provide:
    </p>
    <BulletPoint
      names={[
        'Extended Warranty for the PCR Workstation at a list price of $5,000',
        'On-site Installation (Get a quote by emailing support@opentrons.com)',
        'Expedited Custom Protocols',
      ]}
      noColumns
    />
    <br />
    <table className={styles.services_table}>
      <thead>
        <tr>
          <th>Custom Protocol Type</th>
          <th>1 Workflow</th>
          <th>3 Workflows</th>
          <th>Turnaround Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Standard Workflow Development</td>
          <td>$1,000</td>
          <td>$2,750</td>
          <td>2 weeks</td>
        </tr>
        <tr>
          <td>Expedited Workflow Development</td>
          <td>$1,300</td>
          <td>$3,350</td>
          <td>1 week</td>
        </tr>
      </tbody>
    </table>
    <p>
      If Opentrons hardware does not work for your assay needs, we offer a
      risk-free return on eligible hardware purchases. We provide a full refund
      in the amount found on your purchase order, credit card or pro-forma
      invoice including all associated shipping cost within a 60-day period.
    </p>
  </>
)

export const PROTOCOLS_DATA = {
  description: '',
  hideTable: true,
  hideDisclosures: true,
  bulletListDescription:
    'Check out example protocols below, or browse all PCR protocols <a style="color: #006fff; font-weight: 600;" href="https://protocols.opentrons.com/categories/PCR" target="_blank">here</a>',
  bulletItems: [
    {
      title: 'Generic PCR Prep',
      url: 'https://protocols.opentrons.com/protocol/generic_pcr_prep_1',
    },
    {
      title: 'Plate Reformatting',
      url: 'https://protocols.opentrons.com/protocol/cherrypicking',
    },
    {
      title: 'Custom CSV transfer script',
      url: 'https://protocols.opentrons.com/protocol/csv_transfer',
    },
  ],
  bulletListEndorsement: '',
}

export const LABWARE_URL = 'https://labware.opentrons.com/'

export const MATERIAL_DESCRIPTION =
  'The OT-2 PCR Workstation includes all the hardware and consumables you need to perform automated end-point PCR. It comes with Opentrons’ protocol development service, which provides you with a customized protocol unique to your PCR assay. You’ll also enjoy free access to the Opentrons Protocol Designer, Python API, and Protocol Library.'

export const MATERIAL_DATA = [
  {
    name: 'OT-2 Liquid Handling Robot',
    imgSrc: require('./assets/ot2_liquid_handling_robot.png').default,
  },
  {
    name: '2 Temperature Modules',
    imgSrc: require('./assets/temperature_module.png').default,
  },
  {
    name: 'Thermocycler Module',
    imgSrc: require('./assets/3_thermocycler_module.png').default,
  },
  {
    name: 'P20 Single-Channel Pipette',
    imgSrc: require('./assets/4_p20.png').default,
  },
  {
    name: 'P20 Multi-Channel Pipette',
    imgSrc: require('./assets/4_p20.png').default,
  },
  {
    name: 'Opentrons 20 μl Filter Tips Racks (4 x 9600 tips)',
    imgSrc: require('./assets/6_20uL.jpg').default,
  },
  {
    name: 'NEST 12-Well Reservoir 15 ml (50)',
    imgSrc: require('./assets/7_12well.png').default,
  },
  {
    name: 'Tough 0.2 mL 96-Well PCR Plate (25)',
    imgSrc: require('./assets/8_tough.png').default,
  },
  {
    name: 'NEST 2.0 mL Microcentrifuge Tube (500)',
    imgSrc: require('./assets/9_nest.png').default,
  },
  {
    name: 'Custom protocol development (standard turnaround time)',
  },
]

export const WORKFLOW_DESCRIPTION =
  'The Opentrons NGS Workstation can accommodate low- to high-throughput workflows. Below is an overview of an Opentrons NGS workstation assay, information on what the workstations include, an application note featuring this workstation, your protocol development options, and other information you may need for your assay.'

export const WORKFLOW_DATA = [
  {
    imgSrc: IconDNA,
    name: 'Sample processing & library preparation',
    content:
      'DNA and/or RNA extraction, this also includes QC and normalization steps.',
  },
  {
    imgSrc: IconLibraryPrep,
    name: 'Library preparation',
    content:
      'This can be a variety of steps such as PCS, fragmentation, ligation, tagmentation, quantification, quantitation, QC, normalization, and pooling.',
  },
  {
    imgSrc: IconOT2,
    name: 'Wash',
    content:
      'Wash off impurities with a wash &/or strip buffer. Process until the protein of interest is released.',
  },
  {
    imgSrc: IconDataAndResults,
    name: 'Analysis',
    content:
      'The data coming off a sequencer is analyzed to reveal the results.',
  },
]

export const RESOURCE_NOTE_DATA = [
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
    validationIcon: true,
  },
  {
    resource: 'video',
    contentText: 'Magnetic Module',
    contentLink: 'https://vimeo.com/299671341',
    iconSrc: 'video-sample',
  },
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
  {
    resource: 'protocol',
    contentText: 'Pending new protocol',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
]
