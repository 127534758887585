// extracted by mini-css-extract-plugin
export var p = "PCRWorkstation-module--p--wNJZg";
export var h2 = "PCRWorkstation-module--h2--1usnL";
export var desktop_container = "PCRWorkstation-module--desktop_container--3S0Y-";
export var h4 = "PCRWorkstation-module--h4--814WZ";
export var clean_up_paragraph = "PCRWorkstation-module--clean_up_paragraph--o3-gj";
export var application_container = "PCRWorkstation-module--application_container--MFOZx";
export var container = "PCRWorkstation-module--container--+YcaD";
export var button = "PCRWorkstation-module--button--+GLVl";
export var mobile_image = "PCRWorkstation-module--mobile_image--YfxI0";
export var image = "PCRWorkstation-module--image--tFYBk";
export var badge = "PCRWorkstation-module--badge--dEIiz";
export var bg_color = "PCRWorkstation-module--bg_color--Nlu7-";
export var hero_container = "PCRWorkstation-module--hero_container--d7woc";
export var content_container = "PCRWorkstation-module--content_container--VUElH";
export var price_container = "PCRWorkstation-module--price_container--fKMCg";
export var shop_item_container = "PCRWorkstation-module--shop_item_container--OPTio";
export var resource_span = "PCRWorkstation-module--resource_span--xL0cj";
export var resource_link_container = "PCRWorkstation-module--resource_link_container---x-4S";
export var icon = "PCRWorkstation-module--icon--trQLl";
export var icon_image = "PCRWorkstation-module--icon_image--I-irZ";
export var resource_link = "PCRWorkstation-module--resource_link--TTiRO";
export var resource_note_container = "PCRWorkstation-module--resource_note_container--9DRle";
export var bold_style = "PCRWorkstation-module--bold_style--jhuzA";
export var italic_style = "PCRWorkstation-module--italic_style--Cg59I";
export var mobile_view = "PCRWorkstation-module--mobile_view--xn6Xt";
export var desktop_view = "PCRWorkstation-module--desktop_view--uEhuq";
export var grid = "PCRWorkstation-module--grid--TPYjk";
export var grid_header = "PCRWorkstation-module--grid_header--K4F93";
export var grid_item = "PCRWorkstation-module--grid_item--XTKeK";
export var video = "PCRWorkstation-module--video--rzyNh";
export var video_container = "PCRWorkstation-module--video_container--RH1p7";
export var figure = "PCRWorkstation-module--figure--olwSe";
export var play_icon = "PCRWorkstation-module--play_icon--wHtGL";
export var video_details = "PCRWorkstation-module--video_details--2wlPi";
export var application_data_container = "PCRWorkstation-module--application_data_container--I8BNz";
export var data_section_header = "PCRWorkstation-module--data_section_header--tnrbM";
export var data_section_sub_header = "PCRWorkstation-module--data_section_sub_header--WZbvy";
export var figure_text = "PCRWorkstation-module--figure_text--W8WVO";
export var figure_text_hint = "PCRWorkstation-module--figure_text_hint--a1KED";
export var services_table = "PCRWorkstation-module--services_table--AHEu-";